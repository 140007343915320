import { render, staticRenderFns } from "./releaseTip.vue?vue&type=template&id=2bfa16e6&scoped=true&"
import script from "./releaseTip.vue?vue&type=script&lang=js&"
export * from "./releaseTip.vue?vue&type=script&lang=js&"
import style0 from "./releaseTip.vue?vue&type=style&index=0&id=2bfa16e6&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bfa16e6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/yuanben/data/jenkins/workspace/test-miks-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2bfa16e6')) {
      api.createRecord('2bfa16e6', component.options)
    } else {
      api.reload('2bfa16e6', component.options)
    }
    module.hot.accept("./releaseTip.vue?vue&type=template&id=2bfa16e6&scoped=true&", function () {
      api.rerender('2bfa16e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/appbanner/releaseTip.vue"
export default component.exports
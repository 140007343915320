var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mentions-panel",
      style: _vm.calced_style,
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _vm.positionTop
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name",
              },
            ],
            ref: "inputTop",
            staticClass: "input",
            attrs: { placeholder: _vm.$t("想用@提到谁") },
            domProps: { value: _vm.name },
            on: {
              keyup: _vm.handleKeyUp,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.memberList.length > 0,
              expression: "memberList.length > 0",
            },
          ],
          ref: "scroll-container",
          staticClass: "item-wrapper zmx-scroll",
        },
        _vm._l(_vm.memberList, function (item, idx) {
          return _c(
            "div",
            {
              key: item.id,
              ref: "item-" + idx,
              refInFor: true,
              staticClass: "item",
              class: { active: _vm.activeIdx == idx },
              on: {
                mouseenter: function ($event) {
                  _vm.activeIdx = idx
                },
                click: function ($event) {
                  return _vm.select_member(item)
                },
              },
            },
            [
              _c("div", { staticClass: "face" }, [
                item.head_img && item.head_img.storage_url
                  ? _c("img", {
                      staticClass: "img",
                      attrs: { src: item.head_img.storage_url },
                    })
                  : _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "\n               " +
                          _vm._s(
                            item.name && item.name.toUpperCase().split("")[0]
                          ) +
                          "\n            "
                      ),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _c("div", [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "email" }, [
                    _vm._v(_vm._s(item.email)),
                  ]),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      !_vm.positionTop
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name",
              },
            ],
            ref: "inputBottom",
            staticClass: "input bottom",
            attrs: { placeholder: _vm.$t("想用@提到谁") },
            domProps: { value: _vm.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              },
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
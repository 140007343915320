var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scroll-loading" }, [
    !_vm.is_empty && _vm.itemsLength && _vm.itemsLength === 20
      ? _c(
          "div",
          {
            directives: [
              {
                name: "scroll-loading",
                rawName: "v-scroll-loading",
                value: {
                  handler: _vm.handleEvent,
                  type: _vm.type,
                  scrollHeight: _vm.scrollHeight,
                },
                expression: "{handler:handleEvent,type,scrollHeight}",
              },
            ],
            staticClass: "zmx-loading",
          },
          [_c("i", { staticClass: "el-icon-loading" })]
        )
      : _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.endTextVisible,
                expression: "endTextVisible",
              },
            ],
            staticClass: "empty",
          },
          [
            _c("div", { staticClass: "left" }),
            _vm._v(" "),
            _c("div", { staticClass: "dot" }, [
              _vm._v(_vm._s(_vm.$t("我是有底线的"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
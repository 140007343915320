var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-wrapper" }, [
    _c("img", {
      staticClass: "img-header",
      attrs: { src: require("@/common/images/disclosure_agreement.png") },
    }),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "warn-tip" }, [
          _vm._v(
            "\n           请仔细阅读，并在每条承诺协议中进行确认\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "el-checkbox",
          {
            model: {
              value: _vm.promise_confidential,
              callback: function ($$v) {
                _vm.promise_confidential = $$v
              },
              expression: "promise_confidential",
            },
          },
          [
            _c("span", { staticClass: "checkbox-item" }, [
              _vm._v("承诺对披露方披露的信息进行严格保密"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-checkbox",
          {
            model: {
              value: _vm.promise_no_disclosure,
              callback: function ($$v) {
                _vm.promise_no_disclosure = $$v
              },
              expression: "promise_no_disclosure",
            },
          },
          [
            _c("span", { staticClass: "checkbox-item" }, [
              _vm._v("承诺不向任何第三方披露任何保密信息"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-checkbox",
          {
            model: {
              value: _vm.promise_no_copy,
              callback: function ($$v) {
                _vm.promise_no_copy = $$v
              },
              expression: "promise_no_copy",
            },
          },
          [
            _c("span", { staticClass: "checkbox-item" }, [
              _vm._v("承诺不复制、重现披露方披露的保密信息"),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c("div", { staticClass: "footer-tip" }, [
          _vm._v(
            "所有披露协议中的承诺进行确认后，才能打开文件，文件一旦打开，您将承担对应法律责任"
          ),
        ]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: {
              disabled:
                !_vm.promise_confidential ||
                !_vm.promise_no_disclosure ||
                !_vm.promise_no_copy,
              type: "primary",
            },
            on: { click: _vm.proceed },
          },
          [_vm._v("确认打开")]
        ),
        _vm._v(" "),
        _c("el-button", { on: { click: _vm.refuse } }, [_vm._v("拒绝承诺")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h1", [_vm._v("信息披露协议")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-wrapper" }, [
    _c("div", { staticClass: "header" }, [_vm._v("\n      信息披露\n   ")]),
    _vm._v(" "),
    _vm.step == 0
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "file-img-box" },
              [
                _c("icon-svg", {
                  attrs: { iconName: "miks-icon-" + _vm.file_type },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "file-title" }, [
              _c("div", { staticClass: "link-text" }, [
                _vm._v(_vm._s(_vm.file.title)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "520px",
                height: "1px",
                background: "#E5E5E5",
                margin: "24px 40px",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "tip" }, [
              _vm._v(
                "\n      为了信息披露的安全性和隐私性，请输入接收者的姓名以及手机号\n   "
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-form",
              {
                ref: "form",
                staticClass: "fields",
                attrs: { model: _vm.fields, rules: _vm.formRule },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "receiver" } },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("接收人姓名："),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { placeholder: "输入姓名" },
                      model: {
                        value: _vm.fields.receiver,
                        callback: function ($$v) {
                          _vm.$set(_vm.fields, "receiver", $$v)
                        },
                        expression: "fields.receiver",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "receiverPhone" } },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("接收人手机号："),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { placeholder: "输入手机号" },
                      model: {
                        value: _vm.fields.receiverPhone,
                        callback: function ($$v) {
                          _vm.$set(_vm.fields, "receiverPhone", $$v)
                        },
                        expression: "fields.receiverPhone",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item button" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.proceed },
                      },
                      [_vm._v("生成链接")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c("div", [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "created-title" }, [
            _vm._v("\n        链接已生成\n      "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "tip" }, [
            _vm._v(
              "\n         请分享给你指定的接收人，不要随意地发送他人\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "link-content" }, [
            _vm._v("\n         " + _vm._s(_vm.discloseLink) + "\n      "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "fields" }, [
            _c(
              "div",
              { staticClass: "item button" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.copyLink } },
                  [_vm._v("复制链接")]
                ),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "created-icon" }, [
      _c("i", { staticClass: "el-icon-circle-check" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }